@import "./base.scss";

body {
  font-family: Nobel;
}

p {
  font-family: NobelBook;
}

.nobelBook {
  font-family: NobelBook;
}

#header {
  background-color: $grey;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  img {
    width: 400px;
  }
  // object-fit: contain;
  @media (max-width: 768px) {
    img {
      width: 300px;
    }
  }
}

.hero-container {
  height: auto;
  position: relative;
}

.hero-title {
  position: absolute;
  top: 0px;
  left: 90px;
  @media (max-width: 768px) {
    top: -20px;
    left: 20px;
    h2 {
      font-size: 30px;
    }
  }
  @media (max-width: 520px) {
    top: -40px;
    left: 0px;
    h2 {
      font-size: 16px;
    }
  }
}

.hero-text {
  padding-left: 30px;
  border-left: 1px solid $grey;
}

.bid-title {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 520px) {
    font-size: 20px;
    bottom: -6px;
  }
}

.sm-font-14 {
  @media (max-width: 520px) {
    font-size: 18px;
  }
}

.car-box {
  border: 8px solid lighten($grey, 30);
  padding: 10px 5px;
  width: 350px;
  height: 300px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: NobelBook;
  &_image {
    // min-width: 400px;
    position: relative;
    left: 40px;
  }
  &_title {
    font-size: 20px;
    margin-bottom: 0;
  }
  &_label {
    font-size: 16px;
    margin-bottom: 0;
  }
  &_price {
    font-size: 28px;
  }
  @media (max-width: 520px) {
    width: 100%;
    &_image {
      width: 100%;
      left: 0px;
      top: 0px;
    }
  }
}

.car-info {
  text-align: center;
  max-width: 350px;
  margin: 30px auto;
}

.brown-button {
  background-color: $brown;
  color: lighten($grey, 40);
  width: 100%;
  padding: 20px 0px;
  font-size: 24px;
  border-radius: 0;
  text-transform: uppercase;
  font-family: NobelBook;
  transition: all 0.3s;
  &:hover {
    color: lighten($grey, 50);
    background-color: darken($brown, 5);
  }
  &--light {
    text-transform: uppercase;
    font-size: 28px;
    letter-spacing: 5px;
    font-family: NobelBook;
    background-color: #c3986b;
    border-radius: 0;
    color: lighten($grey, 50);
    width: 100%;
    &:hover {
      background-color: darken(#c3986b, 5);
    }
    @media (max-width: 520px) {
      font-size: 16px;
    }
  }
}

.bg-brown {
  background-color: $brown;
}

.contact-form {
  max-width: 500px;
  margin: 0 auto;
}

.text-input {
  width: 100%;
  text-align: center;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

// range slider

.irs-bar.irs-bar--single,
.irs-line,
.irs--flat .irs-line {
  height: 3px;
  background-color: whitesmoke;
}

.irs-single,
.irs-min,
.irs-max {
  display: none;
}

.irs--flat .irs-handle > i:first-child {
  background-color: $grey;
  height: 60px;
  width: 10px;
  top: -25px;
}

.cdb {
  border-radius: 0;
  background: white;
  color: black;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover,
  &:focus,
  &:active {
    border-radius: 0;
    background: white;
    color: black;
    box-shadow: none;
    outline: 0;
  }
  &:after {
    vertical-align: middle;
  }
}

.cdm {
  min-width: 200px;
  border-radius: 0;
  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
}

.bid-count {
  color: inehrit;
}

.color-box {
  width: 20px;
  height: 20px;
}

.border-parra {
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  padding: 20px 10px;
}

footer {
  img {
    width: 200px;
  }
}

.footer-text {
  font-size: 14px;
  color: $grey;
  opacity: 0.5;
}

a,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

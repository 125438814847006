$grey: #808080;
$brown: #231f20;

@font-face {
  font-family: Nobel;
  src: url("../fonts/NobelBL-Regular.otf");
}

@font-face {
  font-family: NobelBook;
  src: url("../fonts/NobelBL-Book.otf");
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
